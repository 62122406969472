<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="12">
        <RadioGroup
          size="small"
          v-model="taskQuery.type"
          type="button"
          @on-change="changeDemandType"
        >
          <Radio
            v-for="item in demandTypeArray"
            :key="item.value"
            :label="item.value"
          >{{ item.name }}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="6">
        <i-input
          size="small"
          placeholder="关键字：任务名称"
          v-model='taskQuery.keyword'
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          size="small"
          icon="ios-search"
          type="primary"
          class="m-r-5"
          @click="handleSearch"
        >搜索</Button>
        <Button
          size="small"
          type="primary"
          @click="createModal = true"
        >新增需求</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        max-height="720"
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        :loading="dataLoading"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="tableData.totalRow"
          :page-size="taskQuery.pageSize"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          :current="taskQuery.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
    <Modal
      width="400"
      v-model="createModal"
    >
      <p
        slot="header"
        class="text-center"
      >新增需求</p>
      <div class="m-b-10">
        <Select
          v-model="createType"
          size="small"
          placeholder="选择类型"
          @on-change="getNewTaskDefaultName"
        >
          <Option
            v-for="item in taskTypeArray"
            :key="'type_' + item.value"
            :value="item.value"
          >{{ item.name }}</Option>

        </Select>
      </div>
      <div>
        <Input
          v-model="taskName"
          size="small"
          placeholder="填写任务名称（非必填，不填写系统自动生成名称）"
        />
      </div>

      <div slot="footer">
        <Button
          type="text"
          @click="createModal = false"
        >取消</Button>
        <Button
          type="primary"
          :disabled="!createType"
          @click="handelCreateDemand"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { GetCurrentSchedule } from '@/utils/dateFormat'
// import { formartMspTaskStatus } from '@/utils/tagStatus'
import { sysMixins } from '@/assets/mixins/sys'
import { createTask } from '@/api/msp/demandV3'
import { getTaskDefaultName } from '@/api/msp/demand'
import { deleteTask } from '@/api/msp/task'
export default {
  mixins: [sysMixins],
  data () {
    return {
      demandTypeArray: [
        // { value: 5, name: '维修' },
        // { value: 9, name: '维护' },
        { value: 8, name: '巡检' },
        { value: 11, name: '临时任务' },
        { value: 7, name: '下刊申请' },
        { value: 1, name: '自定义上刊' }
      ],
      createModal: false,
      createType: null,
      taskName: '', // 创建任务的名称

      taskQuery: {
        pageNumber: 1,
        pageSize: 15,
        status: null, // 状态(0 待派单 1已派单 null 全部)
        type: this.$store.state.task.curDemandType || 8,
        startDate: '',
        endDate: '',
        keyword: '',
        createDate: '',
        queryInstallFill: false
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskTypeShowColumns: new Map([
        [8, ['name', 'createTime', 'createUserName', 'statusQuantity', 'operate']], // 巡检
        [7, ['name', 'schedule', 'createTime', 'updateTime', 'createUserName', 'statusQuantity', 'operate']], // 下刊申请
        [1, ['name', 'createTime', 'createUserName', 'statusQuantity', 'operate']], // 自定义上刊（画面填充）
        [11, ['name', 'createTime', 'createUserName', 'statusQuantity', 'operate']] // 临时任务
      ]),
      tableColumns: [],
      dataLoading: false
    }
  },
  computed: {
    tableData: {
      get () {
        return this.$store.state.task.tableData
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }
    },
    taskTypeArray () {
      // 新增任务类型，排除“下刊申请”任务
      return this.demandTypeArray.filter(x => x.value !== 7)
    }
    // createDemandTypeArray () {
    //   return this.demandTypeArray.filter(x => x.value !== 7)
    // }
  },
  created () {
    this.setLinkTagArray({ key: 'DemandIndex', value: '任务列表', actived: true })
    this.setActivedTagName('DemandIndex')
    this.setLinkRouterMaps(['DemandIndex', () => { this.handlePage() }])
  },
  mounted () {
    // 如果存在搜索条件状态，则取之
    if (this.$store.state.task.indexSearchParams) {
      Object.assign(this.taskQuery, this.$store.state.task.indexSearchParams)
    }
    this.initData()
    this.tableColumns = this.getTableColumns()
  },
  beforeDestroy () {
    this.tableData = {}
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        name: { title: '任务名称', key: 'name', align: 'center' },
        createTime: { title: '发起时间', key: 'createTime', align: 'center' },
        updateTime: { title: '更新时间', key: 'updateTime', align: 'center' },
        createUserName: { title: '发起人', key: 'createUserName', align: 'center' },
        createCompanyName: { title: '发起公司', key: 'createCompanyName', align: 'center' },
        advertiserName: {
          title: '客户名称',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskOrder.advertiserName)
          }
        },
        brandName: {
          title: '品牌',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskOrder.brandName)
          }
        },
        schedule: {
          title: '投放档期',
          align: 'center',
          render: (h, params) => {
            if (params.row.startDate === '' || params.row.endDate === '') {
              return h('span', '-')
            } else {
              return h('span', GetCurrentSchedule(params.row.taskOrder.startDate, params.row.taskOrder.endDate, false))
            }
          }
        },
        statusQuantity: {
          title: '总数/不执行/已派单/执行中/已完成',
          align: 'center',
          render: (h, params) => {
            if (params.row.statusQuantity && Object.keys(params.row.statusQuantity).length) {
              return h('div', [
                h('span', {
                  style: {
                    color: (params.row.statusQuantity.draftQuantity + params.row.statusQuantity.waittingQuantity) > 0 ? '#ef4f4f' : ''
                  }
                }, params.row.statusQuantity.totalQuantity),
                h('span', ` / ${params.row.statusQuantity.terminatedQuantity} / ${params.row.statusQuantity.assignedQuantity} / ${params.row.statusQuantity.executingQuantity} / ${params.row.statusQuantity.finishedQuantity}`)
              ])
            }
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            var html = [h('a', {
              style: { marginRight: '5px' },
              on: {
                click: () => {
                  this.handleViewDemand(params.row)
                }
              }
            }, '查看')]
            if (this.taskQuery.type !== 7) {
              if (params.row.statusQuantity.totalQuantity === 0 || params.row.statusQuantity.draftQuantity > 0) {
                // 草稿状态，排除下刊申请
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handelAddTask(params.row)
                    }
                  }
                }, '添加'))
              }
              if (params.row.statusQuantity.draftQuantity === params.row.statusQuantity.totalQuantity) {
                html.push(h('a', {
                  style: { marginRight: '5px', color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.handelDeleteTask(params.row)
                    }
                  }
                }, '删除'))
              }
            }

            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.taskQuery.type)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    initData () {
      // 保存搜索条件状态
      // this.taskQuery.status = this.taskQuery.type === 7 ? JSON.stringify([0, 1, -3]) : null
      this.dataLoading = true
      this.taskQuery.queryInstallFill = this.taskQuery.type === 1
      this.$store.commit('set_index_search_params', this.taskQuery)
      this.$store.dispatch('getTaskData', this.taskQuery).finally(() => { this.dataLoading = false })
    },
    handleSearch () {
      this.taskQuery.pageNumber = 1
      this.initData()
    },
    changeDemandType () {
      if (this.taskQuery.type !== 11) {
        // 非临时任务，隐藏左侧操作栏
        this.setLeftComponent()
        this.setShowLeftBlock(false)
      }
      this.taskQuery.pageNumber = 1
      this.initData()
      this.tableColumns = this.getTableColumns()
    },
    getNewTaskDefaultName () {
      if (!this.createType) {
        this.taskName = ''
        return false
      }
      getTaskDefaultName({ tasktype: this.createType }).then(res => {
        if (res && !res.errcode) {
          this.taskName = res
        }
      })
    },
    changePageSize (pageSize) {
      this.taskQuery.pageSize = pageSize
      this.taskQuery.pageNumber = 1
      this.initData()
    },
    changePage (curPage) {
      this.taskQuery.pageNumber = curPage
      this.initData()
    },
    /**
   * 创建需求
   */
    handelCreateDemand () {
      if (!this.createType) {
        this.$Notice.error({ desc: '请选择一个类型' })
        return false
      }
      createTask({ tasktype: this.createType, taskName: this.taskName }).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.taskQuery.type = this.createType
          this.createType = null
          this.$store.commit('set_current_demand_type', this.taskQuery.type)
          this.initData()
          this.tableColumns = this.getTableColumns()
        }
      })
      this.createModal = false
    },
    handelAddTask (data) {
      this.$store.commit('set_current_taskId', data.id)
      this.$store.commit('set_current_demand_type', data.type)
      switch (data.type) {
        case 1:
          // 自定义上刊（画面填充）处理
          this.setShowLeftBlock(true)
          this.setLeftComponent('UninstallScreen')
          this.setBottomComponent('AddTask')
          break
        case 7:
          // 下刊申请
          this.setShowLeftBlock(false)
          this.setLeftComponent('')
          this.setBottomComponent('AddIssueTask')
          break
        case 11:
          // 临时任务处理
          this.setShowLeftBlock(true)
          this.$store.commit('set_opreate_type', 1) // 新增操作标识设置
          this.setLeftComponent('SetTempTask')
          break

        default:
          this.setShowLeftBlock(false)
          this.setLeftComponent('')
          this.setBottomComponent('AddTask')
          break
      }
    },
    /**
     * 删除任务
     */
    handelDeleteTask (data) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该任务？',
        onOk: () => {
          deleteTask({ taskId: data.id }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleViewDemand (data) {
      this.$store.commit('set_current_demand_type', data.type)
      this.$store.commit('set_current_taskId', data.id)
      this.$store.commit('set_task_status', data.status)
      this.setBottomComponent('DemandDetial')
    },
    handlePage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    }
  }
}
</script>
